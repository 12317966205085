/* Reset CSS */

/* General Reset */
*,
*::before,
*::after {
	box-sizing: border-box;
}

:root {
	font-size: 16px; /* Default root font size */
}

body {
	margin: 0;
	font-family: sans-serif;
	font-size: 1rem;
	font-weight: normal;
	line-height: 1.5;
	color: #000;
	text-align: left;
	background-color: #fff;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}

hr {
	margin: 1rem 0;
	color: inherit;
	background-color: currentColor;
	border: 0;
	opacity: 0.5;
	height: 1px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-family: inherit;
	font-weight: bold;
	line-height: 1.2;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr[title] {
	text-decoration: underline dotted;
	cursor: help;
	text-decoration-skip-ink: none;
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul {
	padding-left: 2rem;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

dt {
	font-weight: bold;
}

dd {
	margin-bottom: 0.5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 0.875em;
}

mark {
	padding: 0.2em;
	background-color: yellow;
}

sub,
sup {
	position: relative;
	font-size: 0.75em;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}
sup {
	top: -0.5em;
}

a {
	color: #e9ecef;
	text-decoration: underline;
}

a:not([href]):not([class]) {
	color: inherit;
	text-decoration: none;
}

pre,
code,
kbd,
samp {
	font-family: monospace;
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override;
}

pre {
	display: block;
	margin: 0 0 1rem;
	overflow: auto;
	color: inherit;
}

code {
	color: inherit;
	word-wrap: break-word;
}

figure {
	margin: 0 0 1rem;
}

img,
svg {
	vertical-align: middle;
}

table {
	caption-side: bottom;
	border-collapse: collapse;
}

caption {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	color: gray;
	text-align: left;
}

th {
	font-weight: bold;
	text-align: inherit;
}

label {
	display: inline-block;
}

button {
	border-radius: 0;
	-webkit-appearance: button;
}

button:focus:not(:focus-visible) {
	outline: 0;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
select {
	text-transform: none;
}

[role='button'] {
	cursor: pointer;
}

textarea {
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	float: left;
	width: 100%;
	padding: 0;
	margin-bottom: 0.5rem;
	font-weight: bold;
	line-height: inherit;
}

iframe {
	border: 0;
}

[hidden] {
	display: none !important;
}
